<template>
  <div class="empty-page">
    <img
      class="empty-img"
      src="https://img.glztj.com/images/202309/457994837835845.png"
      alt=""
    />
    <div class="header">当前还未添加家属</div>
    <div class="desc">
      当前暂时没有任何家属信息，点击下方按钮去添加您的家属吧～
    </div>
    <van-button
      class="button"
      round
      type="primary"
      @click="$router.push('/group-contact-detail')"
      >添加家属</van-button
    >
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.empty-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 46px;
}
.empty-img {
  width: 150px;
  height: 150px;
}

.header {
  font-size: 18px;
  font-weight: bold;
  color: #2c2f37;
}

.desc {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #878d99;
}

.button {
  margin-top: 40px;
  width: 150px;
  height: 40px;
}
</style>