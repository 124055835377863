<template>
  <div class="list-container">
    <div class="item" v-for="item in list" :key="item.id">
      <div class="item-right">
        <div class="avatar-container">
          <img
            src="https://img.glztj.com/images/202309/457948798017605.png"
            alt=""
          />
          <img
            class="icon-sex"
            :src="item.sex === 1 ? iconMan : iconWomen"
            alt=""
          />
        </div>
        <div>
          <div class="user-name">{{ item.name }}</div>
          <div class="phone">{{ item.phone }}</div>
        </div>
      </div>

      <div class="item-right">
        <span class="primary-button" @click="onSwitchUser(item.id)">切换</span>
        <span class="primary-button" @click="jumpEdit(item.id)">编辑</span>
      </div>
    </div>
  </div>
</template>

<script>
import iconMan from "@/assets/icon-sex-man.png";
import iconWomen from "@/assets/icon-sex-women.png";
import { Dialog } from "vant";
import { switchContact } from "@/api/group.js";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconMan,
      iconWomen,
    };
  },
  methods: {
    jumpEdit(id) {
      if (!id) return;
      this.$router.push("/group-contact-detail?id=" + id);
    },
    onSwitchUser(id) {
      if (!id) return;
      Dialog.confirm({
        title: "切换亲属账号",
        message: "是否切换亲属账号代预约？",
        confirmButtonColor: "#00c4b3",
      })
        .then(() => {
          // on confirm
          this.switchLogin(id);
        })
        .catch(() => {
          // on cancel
        });
    },
    async switchLogin(id) {
      const res = await switchContact({
        id,
      });
      if (res.success) {
        this.$store.commit("setToken", res.data.token);
        this.$store.commit("setMobile", res.data.phone);
        this.$router.replace(
          "/group-profile?hospitalId=" + this.$store.state.hospitalId
        );
      } else {
        this.$toast(res.message);
      }
    },
  },
};
</script>

<style scoped>
.list-container {
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
}
.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  background-color: #fff;
}

.item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #edeff0;
  transform: scaleY(0.5);
}

.avatar-container {
  position: relative;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.icon-sex {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
}

.item-right {
  display: flex;
  align-items: center;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
  color: #2c2f37;
}

.phone {
  margin-top: 5px;
  font-size: 14px;
  color: #878d99;
}

.item-right {
  display: flex;
}

.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 26px;
  font-size: 13px;
  font-weight: bold;
  background: rgba(0, 196, 179, 0.1);
  color: #00c4b3;
  border: 1px solid#00c4b3;
  border-radius: 15px;
}

.primary-button:not(:first-child) {
  margin-left: 10px;
}
</style>