<template>
  <div>
    <div class="body" v-if="!showEmpty">
      <list :list="list"></list>
      <div class="button-container">
        <van-button
          style="height: 40px"
          type="primary"
          round
          block
          @click="$router.push('/group-contact-detail')"
          >添加家属</van-button
        >
      </div>
    </div>
    <empty v-else></empty>
  </div>
</template>

<script>
import list from "@/views/components/group-contact-list/list.vue";
import empty from "@/views/components/group-contact-list/empty.vue";

import { getContactList } from "@/api/group.js";
export default {
  components: {
    list,
    empty,
  },
  data() {
    return {
      list: [],
      showEmpty: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$setTitle("我的家属");
  },
  methods: {
    async getList() {
      const res = await getContactList();
      if (!res?.length) {
        this.showEmpty = true;
        return;
      }
      this.list = res;
    },
  },
};
</script>

<style>
html body {
  background-color: #f4f4f4;
}
</style>

<style scoped>
.body {
  padding-bottom: 50px;
}
.button-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 20px;
  background-color: #fff;
}
</style>